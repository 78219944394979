import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby'
import Layoutas from '../../components/Layout';
import RefDataDate from '../../components/RefDataDate';
import Mapbox from '../../mapbox'
import Chart from '../../components/Chart';

import { Select, Table } from 'antd';
import '../index.css';

import newnew from '/images/wepik-photo-mode-2022619-143425.webp';

import api from '../../util/api';

const { Option } = Select;

const PagePMS = (props) => {
  const [initData, setInitData] = useState(null)
  const [filter, setFilter] = useState({
    view: 'country',
    year: "2564",
  });
  const [data, setData] = useState({
    group_institute: [],
    group_province: [],
    group_target: []
  })
  const chart_color = ['#059669', '#10b981', '#34d399', '#6ee7b7', '#a7f3d0', '#d1fae5', '#ecfdf5',]

  useEffect(async () => {
    let dataSummaryAllyear = await api.getInitData("pms-overview");
    setInitData(dataSummaryAllyear);
  }, [])

  useEffect(() => {
    if (initData) {
      let _data = {}
      _data.group_institute = initData.group_institute.filter(item => item.year === filter.year).sort((a, b) => b.countProject - a.countProject)
      _data.group_province = initData.group_province.filter(item => item.year === filter.year).sort((a, b) => b.countProject - a.countProject)
      _data.group_target = initData.group_target.filter(item => item.year === filter.year).sort((a, b) => b.countProject - a.countProject)
      setData(_data)
    }
  }, [filter,initData])

  return (
    <Layoutas Role={['eef']}>
      < div className='bg-gray-200'>
        <div style={{
          width: '100%',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          backgroundImage: `url(${newnew})`,
          display: 'flex'
        }} >
          <h1 className="font-bold text-2xl md:text-3xl xl:text-5xl text-center text-white mt-[120px] w-full ">ระบบบริหารจัดการโครงการ (PMS)</h1>
        </div>
        <div className="max-w-[1300px] rounded-3xl m-auto -mt-[70vh] p-8 bg-white bordered">
          <div className="font-bold md:text-xl lg:text-3xl mb-2 text-[#0E3165] text-center">ภาพรวมโครงการ กสศ.</div>
          <div className="flex flex-row mb-2">
            <div>
              <span className="text-sm m-0 ml-[9px]" >ปี: </span>
              <Select
                showSearch
                placeholder="ปีการศึกษา"
                value={filter.year}
                style={{ width: 180, margin: 4 }}
                onChange={(val) => {
                  setFilter({ ...filter, year: val })
                }}
              >
                {
                  [...new Set(['2565', '2564', '2563', '2562', '2561'])]
                    .filter(x => x !== 'ไม่ระบุ')
                    .sort()
                    .map(x => <Option value={x}>{x}</Option>)
                }
              </Select>
            </div>
          </div>
          <div className='grid grid-cols-1 xl:grid-cols-2 gap-4'>
            <div className='col-span-1'>
              <Chart
                containerProps={{ style: { height: "500px" } }}
                className='p-4'
                options={{
                  type: 'pieNotEdges',
                  title: 'จำนวนโครงการแยกตามสำนัก',
                  plotOptions: {
                    pie: {
                      allowPointSelect: true,
                      cursor: 'pointer',
                      showInLegend: true,
                      colors: chart_color,
                      dataLabels: {
                        distance: -30,
                        format: '{point.percentage:.1f} %'
                      }
                    },
                  },
                }}
                data={
                  [{
                    name: 'นร.ทุนนวัตกรรมสายอาชีพชั้นสูง',
                    colorByPoint: true,
                    data: data.group_institute.map((item) => { return { name: item.name, y: item.countProject } }),
                  }]
                } />
            </div>
            <div className='col-span-1'>
              <Chart
                containerProps={{ style: { height: "500px" } }}
                className='p-4'
                options={{
                  type: 'pieNotEdges',
                  title: 'จำนวนงบประมาณโครงการแยกตามสำนัก',
                  plotOptions: {
                    pie: {
                      allowPointSelect: true,
                      cursor: 'pointer',
                      showInLegend: true,
                      colors: chart_color,
                      dataLabels: {
                        distance: -30,
                        format: '{point.percentage:.1f} %'
                      }
                    },
                  },
                }}
                data={
                  [{
                    name: 'นร.ทุนนวัตกรรมสายอาชีพชั้นสูง',
                    colorByPoint: true,
                    data: data.group_institute.map((item) => { return { name: item.name, y: item.sumMoney } }),
                  }]
                } />
            </div>
          </div>

          <div>
            <div className="flex flex-row flex-row">
              <Table
                size="small"
                bordered
                scroll={{ x: 800, y: 500 }}
                style={{ width: '100%' }}
                pagination={{ pageSize: 10 }}
                dataSource={data.group_institute}
                columns={[
                  {
                    title: 'ชื่อสำนัก',
                    dataIndex: 'name',
                    key: 'name',
                    width: 70,
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "left" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    sorter: (a, b) => a.name.length - b.name.length,
                  },
                  {
                    title: 'จำนวนโครงการ',
                    dataIndex: 'countProject',
                    key: 'countProject',
                    width: 70,
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "center" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    sorter: (a, b) => a.countProject - b.countProject,
                  },
                  {
                    title: 'งบประมาณที่ใช้ในโครงการ',
                    dataIndex: 'sumMoney',
                    key: 'sumMoney',
                    width: 70,
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "center" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    sorter: (a, b) => a.sumMoney - b.sumMoney,
                  },
                ]}
                summary={pageData => {
                  let totalBorrow = 0;
                  let totalRepayment = 0;

                  pageData.forEach(({ borrow, repayment }) => {
                    totalBorrow += borrow;
                    totalRepayment += repayment;
                  });

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>รวม</Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <div className='text-center'>{data.group_institute.reduce((acc, cur) => (acc + cur.countProject), 0).toLocaleString("en-US")}</div>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <div className='text-center'>{data.group_institute.reduce((acc, cur) => (acc + cur.sumMoney), 0).toLocaleString("en-US")}</div>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              >

              </Table>
            </div>
          </div>

          <div className='grid grid-cols-1 xl:grid-cols-2 gap-4'>
            <div className='col-span-1'>
              <Chart
                containerProps={{ style: { height: "500px" } }}
                className='p-4'
                options={{
                  type: 'spiderWeb',
                  title: 'งบประมาณโครงการ',
                  categories: data.group_institute.map(item => item.name)
                }}
                data={
                  [{
                    name: 'งบประมาณโครงการ',
                    data: data.group_institute.map(item => item.sumMoney),
                    pointPlacement: 'on'
                  }]
                } />
            </div>
            <div className='col-span-1'>
              <Chart
                containerProps={{ style: { height: "500px" } }}
                className='p-4'
                options={{
                  type: 'spiderWeb',
                  title: 'จำนวนโครงการ',
                  categories: data.group_institute.map(item => item.name)
                }}
                data={
                  [{
                    name: 'จำนวนโครงการ',
                    data: data.group_institute.map(item => item.countProject),
                    pointPlacement: 'on'
                  }]
                } />
            </div>
          </div>
          <RefDataDate
            title=""
            subjectTitle="*ประมวลผล ณ เดือนเมษายน 2565"
          />
        </div >
      </div >
    </Layoutas >
  )

}

export default PagePMS
