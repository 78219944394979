import React from 'react'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'

if (typeof Highcharts === 'object') {
  HighchartsMore(Highcharts);
}

const Chart = (props) => {
  const { options, data, containerProps, style, click } = props
  const [option, setOption] = React.useState({})

  // React.useEffect(() => {
  // HighchartsMore(Highcharts)
  // },[])

  React.useEffect(() => {
    Highcharts.setOptions({
      chart: {

        style: {
          fontFamily: 'Kanit'
        }
      },
      exporting: {
        chartOptions: {
          chart: {
            events: {
              load: function () {
                this.renderer.image('https://obec.blob.core.windows.net/cdn/images/iseelogo1.png', 570, 5, 25, 25)
                  .add();
                this.renderer.image('https://obec.blob.core.windows.net/cdn/images/eef_watermark.png', 160, 70, 332, 285)
                  .add();
                this.renderer.image('https://obec.blob.core.windows.net/cdn/images/eeflogo.png', 535, 5, 30, 25)
                  .add();
              }
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      lang: {
        thousandsSep: ','
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500,
            maxHeight: 400
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            },
            yAxis: {
              labels: {
                align: 'left',
                x: 0,
                y: -5
              },
              title: {
                text: null
              }
            },
            subtitle: {
              text: null
            },
            credits: {
              enabled: false
            }
          }
        }]
      }
    });
    if (options.type === 'column' || options.type === 'bar') {
      options.series = convertDataSet(data)
      setOption(columnChart(options))
    } else if (options.type === 'pie') {
      options.series = convertDataSet(data)
      setOption(pieChart(options))
    } else if (options.type === 'donut') {
      options.series = convertDataSet(data)
      setOption(donutChart(options))
    } else if (options.type === 'line') {
      options.series = convertDataSet(data)
      setOption(lineChart(options))
    } else if (options.type === 'areaspline') {
      options.series = convertDataSet(data)
      setOption(lineAndAreaChart(options))
    } else if (options.type === 'stackedAndColumn') {
      options.series = convertDataSet(data)
      setOption(stackedAndColumn(options))
    } else if (options.type === 'pieNotEdges') {
      options.series = convertDataSet(data)
      setOption(pieNotEdgesChart(options))
    } else if (options.type === 'spiderWeb') {
      options.series = convertDataSet(data)
      setOption(spiderWeb(options))
    } else if (options.type === 'customchart') {
      setOption(customChart(options))
    }

  }, [props]);


  const convertDataSet = (_data) => {
    return _data
  }

  const columnChart = (_options) => {
    const op = {
      chart: {
        type: _options.type,
        style: {
          fontFamily: 'Kanit'
        }
      },
      title: {
        text: (_options.title ? _options.title : '')
      },
      subtitle: {
        text: (_options.subtitle ? _options.subtitle : '')
      },
      xAxis: _options.xAxis ? _options.xAxis : {
        categories: _options.categories,
        crosshair: true
      },
      yAxis: _options.yAxis ? _options.yAxis : {
        min: 0,
        title: {
          text: _options?.yAxis?.title?.text ? _options.yAxis.title.text : ''
        },
        labels: {
          formatter: function () {
            if (this.value >= 10e5) {
              return Highcharts.numberFormat(this.value / 10e5, 1) + " ล้าน"
            } else if (this.value >= 10e4) {
              return Highcharts.numberFormat(this.value / 10e4, 1) + " แสน"
            } else if (this.value >= 10e3) {
              return Highcharts.numberFormat(this.value / 10e3, 1) + " หมื่น"
            } else if (this.value >= 10e2) {
              return Highcharts.numberFormat(this.value / 10e2, 1) + " พัน"
            } else {
              return this.value
            }
          }
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: _options.plotOptions ? _options.plotOptions : {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        },
        series: {
          point: {
            events: {
              click: function (e) {
                click({
                  e,
                  category: this.category,
                  name: this.series.name,
                })
              }
            }
          }
        }
      },
      series: _options.series,
      credits: {
        enabled: false
      },
    }
    return op
  }

  const pieChart = (_options) => {
    const op = {
      chart: {
        style: {
          fontFamily: 'Kanit'
        },
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: (_options.title ? _options.title : '')
      },
      subtitle: {
        text: (_options.subtitle ? _options.subtitle : '')
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: _options.plotOptions ? _options.plotOptions : {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        },
        series: {
          point: {
            events: {
              click: function (e) {
                click({
                  e,
                  category: this.category,
                  name: this.series.name,
                })
              }
            }
          }
        }
      },
      series: _options.series,
      credits: {
        enabled: false
      },

    }
    return op
  }

  const donutChart = (_options) => {
    const op = {
      chart: {
        style: {
          fontFamily: 'Kanit'
        },
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: _options.title ? {
        text: _options.title,
        align: 'center',
        verticalAlign: 'middle',
        y: 15
      } : { text: '' },
      subtitle: {
        text: (_options.subtitle ? _options.subtitle : '')
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: _options.plotOptions ? _options.plotOptions : {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        },
        series: {
          point: {
            events: {
              click: function (e) {
                click({
                  e,
                  category: this.category,
                  name: this.series.name,
                })
              }
            }
          }
        }
      },
      series: _options.series,
      credits: {
        enabled: false
      },

    }
    return op
  }

  const pieNotEdgesChart = (_options) => {
    const op = {
      chart: {
        style: {
          fontFamily: 'Kanit'
        },
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: (_options.title ? _options.title : '')
      },
      subtitle: {
        text: (_options.subtitle ? _options.subtitle : '')
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: _options.plotOptions ? _options.plotOptions : {
        pie: {
          dataLabels: {
            distance: -30,
            format: '{point.percentage:.1f} %'
          }
        },
      },
      series: _options.series,
      credits: {
        enabled: false
      },

    }
    return op
  }

  const stackedAndColumn = (_options) => {
    const op = {
      chart: {
        style: {
          fontFamily: 'Kanit'
        },
        type: 'column'
      },
      title: {
        text: (_options.title ? _options.title : '')
      },
      subtitle: {
        text: (_options.subtitle ? _options.subtitle : '')
      },
      tooltip: _options.tooltip ? _options.tooltip : {
        formatter: function () {
          return '<b>' + this.x + '</b><br/>' +
            this.series.name + ': ' + Highcharts.numberFormat(this.y, 0) + ' คน<br/>' +
            'จาก: ' + Highcharts.numberFormat(this.point.stackTotal, 0) + ' คน';
        }
      },

      // headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      // pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      //   '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
      // footerFormat: '</table>',
      // shared: true,
      // useHTML: true
      yAxis: _options.yAxis ? _options.yAxis : {
        allowDecimals: false,
        min: 0,
        title: {
          text: _options?.yAxis?.title?.text ? _options.yAxis.title.text : ''
        },
        labels: {
          color: "white",
          formatter: function () {
            if (this.value >= 10e5) {
              return Highcharts.numberFormat(this.value / 10e5, 1) + " ล้าน"
            } else if (this.value >= 10e4) {
              return Highcharts.numberFormat(this.value / 10e4, 1) + " แสน"
            } else if (this.value >= 10e3) {
              return Highcharts.numberFormat(this.value / 10e3, 1) + " หมื่น"
            } else if (this.value >= 10e2) {
              return Highcharts.numberFormat(this.value / 10e2, 1) + " พัน"
            } else {
              return this.value
            }
          }
        },
      },
      xAxis: _options.xAxis ? _options.xAxis : {
        categories: _options.categories,
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: _options.plotOptions ? _options.plotOptions : {
        column: {
          stacking: 'normal'
        },
        series: {
          point: {
            events: {
              click: function (e) {
                click({
                  e,
                  category: this.category,
                  name: this.series.name,
                })
              }
            }
          }
        }
      },

      credits: {
        enabled: false
      },

      series: _options.series
    }
    return op
  }

  const lineChart = (_options) => {
    const op = {
      chart: {
        style: {
          fontFamily: 'Kanit'
        },
      },
      title: {
        text: (_options.title ? _options.title : '')
      },
      subtitle: {
        text: (_options.subtitle ? _options.subtitle : '')
      },
      yAxis: {
        title: {
          text: _options?.yAxis?.title?.text ? _options.yAxis.title.text : ''
        },
        // labels: {
        //   color: "white",
        //   formatter: function () {
        //     if (this.value >= 10e5) {
        //       return Highcharts.numberFormat(this.value / 10e5, 1) + " ล้าน"
        //     } else if (this.value >= 10e4) {
        //       return Highcharts.numberFormat(this.value / 10e4, 1) + " แสน"
        //     } else if (this.value >= 10e3) {
        //       return Highcharts.numberFormat(this.value / 10e3, 1) + " หมื่น"
        //     } else if (this.value >= 10e2) {
        //       return Highcharts.numberFormat(this.value / 10e2, 1) + " พัน"
        //     } else {
        //       return this.value
        //     }
        //   }
        // },
      },
      xAxis: {
        categories: _options.categories,
      },
      tooltip: _options.tooltip ? _options.tooltip : {},
      legend: _options.legend ? _options.legend : {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      },
      plotOptions: {
        series: {
          // label: {
          //   connectorAllowed: false
          // },
          // pointStart: 2010,
          point: {
            events: {
              click: function (e) {
                click({
                  e,
                  category: this.category,
                  name: this.series.name,
                })
              }
            }
          }

        }
      },
      credits: {
        enabled: false
      },

      series: _options.series
    }
    return op
  }

  const lineAndAreaChart = (_options) => {
    const op = {
      chart: {
        type: 'areaspline',
        style: {
          fontFamily: 'Kanit'
        },
      },
      title: {
        text: (_options.title ? _options.title : '')
      },
      subtitle: {
        text: (_options.subtitle ? _options.subtitle : '')
      },
      yAxis: {
        title: {
          text: _options?.yAxis?.title?.text ? _options.yAxis.title.text : ''
        },
        labels: {
          color: "white",
          formatter: function () {
            if (this.value >= 10e5) {
              return Highcharts.numberFormat(this.value / 10e5, 1) + " ล้าน"
            } else if (this.value >= 10e4) {
              return Highcharts.numberFormat(this.value / 10e4, 1) + " แสน"
            } else if (this.value >= 10e3) {
              return Highcharts.numberFormat(this.value / 10e3, 1) + " หมื่น"
            } else if (this.value >= 10e2) {
              return Highcharts.numberFormat(this.value / 10e2, 1) + " พัน"
            } else {
              return this.value
            }
          }
        },
      },
      xAxis: {
        categories: _options.categories,
      },

      legend: _options.legend ? _options.legend : {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      },
      plotOptions: {
        areaspline: {
          fillOpacity: 0.5
        },
        series: {
          // label: {
          //   connectorAllowed: false
          // },
          // pointStart: 2010,
          point: {
            events: {
              click: function (e) {
                click({
                  e,
                  category: this.category,
                  name: this.series.name,
                })
              }
            }
          }

        }
      },
      credits: {
        enabled: false
      },

      series: _options.series
    }
    return op
  }

  const spiderWeb = (_options) => {
    const op = {
      chart: {
        polar: true,
        type: 'line',
        style: {
          fontFamily: 'Kanit'
        }
      },
      title: {
        text: (_options.title ? _options.title : '')
      },
      subtitle: {
        text: (_options.subtitle ? _options.subtitle : '')
      },
      yAxis: (_options.yAxis ? _options.yAxis : {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0
      }),
      pane: {
        size: '80%'
      },
      xAxis: {
        categories: _options.categories,
        tickmarkPlacement: 'on',
        lineWidth: 0
      },

      legend: _options.legend ? _options.legend : {
        layout: 'vertical',
        align: 'center',
        verticalAlign: 'bottom'
      },
      plotOptions: {
        series: {
          // label: {
          //   connectorAllowed: false
          // },
          // pointStart: 2010,
          point: {
            events: {
              click: function (e) {
                click({
                  e,
                  category: this.category,
                  name: this.series.name,
                })
              }
            }
          }

        }
      },
      credits: {
        enabled: false
      },

      series: _options.series
    }
    return op
  }

  const customChart = (_options) => {
    const op = {
      ..._options.chart
    }
    return op
  }

  return (
    <div className={props.className ? props.className : ''}>
      <HighchartsReact highcharts={Highcharts}
        options={option}
        containerProps={containerProps}
        style={style}
      // style={{ width: '100px', height: '30px' }}
      />
    </div>
  )
}

export default Chart
